import React, { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { ConfirmContext } from '../../components/ConfirmModal';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import createIconImg from '../../../images/createIcon.png';
import editDSIcon from '../../../images/editDS.png';
import deleteDSIcon from '../../../images/deleteDS.png';

function ShowMonitDataStructures(props) {
    const { dataStructures } = useOutletContext();
    const navigate = useNavigate();
    const { confirm } = useContext(ConfirmContext);

    // 수정 버튼 클릭 이벤트
    const onClickEditBtn = (e, selData) => {
        e.preventDefault();
        navigate(`/home/dataStructure/monitoring/edit/${selData}`);
    };

    // 추가 버튼 클릭 이벤트
    const onClickCreateBtn = (e) => {
        e.preventDefault();
        navigate('/home/dataStructure/monitoring/create');
    };

    // 삭제 버튼 클릭 이벤트
    const onClickDeleteBtn = async (e, selData) => {
        e.preventDefault();
        const selectedData = dataStructures.filter(el => el.dDsId === selData)[0];
        const result = await confirm(`${selectedData.name}를(을) 삭제하시겠습니까?`, '삭제', '취소');
        if (result) {
            // 삭제
            await axios.post(`${API_URL}/monitoring-manage/deleteDataStructure`, { dDsId: selData }).then(res => {
                const { success } = res.data;
                if (success) {
                    navigate(0);
                }
            })
        }
    };

    return (
        <React.Fragment>

            <div className='showMonitDataStructures'>
                <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>세부 데이터 구조</p>
                <h2 className='pageTitle'>세부 데이터 구조</h2>
                <div className='sumDsArea'>
                    <div className='dataStructureTblArea'>
                        <ul className='dataStructureTbl'>
                            <li className='row th'>
                                <ul>
                                    <li className='col name'>데이터 이름</li>
                                    <li className='col class'>데이터 분류</li>
                                    <li className='col rawNo'>센서 데이터 수</li>
                                    <li className='col rawName'>센서 데이터 이름</li>
                                    <li className='col rawType'>데이터 유형</li>
                                    <li className='col rawDim'>단위</li>
                                    <li className='col mqttTopic'>MQTT 토픽</li>
                                    <li className='col comment'>comment</li>
                                    <li className='col edit'>수정</li>
                                    <li className='col delete'>삭제</li>
                                </ul>
                            </li>
                            {
                                dataStructures.map((item, index, array) => {
                                    return (
                                        <li key={index} className='row'>
                                            <ul>
                                                <li className='col name'>{item.name}</li>
                                                <li className='col class'>{item.class}</li>
                                                <li className='col rawNo'>{item.rawNo}</li>
                                                <li className='col rawName'><ul>{
                                                    item.rawName.map((e, idx) => <li className='innerCol rawName' key={idx}>{e}</li>)
                                                }</ul></li>
                                                <li className='col rawType'><ul>{
                                                    item.rawType.map((e, idx) => <li className='innerCol rawType' key={idx}>{e}</li>)
                                                }</ul></li>
                                                <li className='col rawDim'><ul>{
                                                    item.rawDim.map((e, idx) => <li className='innerCol rawDim' key={idx}>{e}</li>)
                                                }</ul></li>
                                                <li className='col mqttTopic'>{item.mqttTopic}</li>                                                
                                                <li className='col comment'>{item.comment}</li>
                                                <li className='col edit'>
                                                    <div className='innerDiv edit'>
                                                        <button onClick={e => onClickEditBtn(e, item.dDsId)}>
                                                            <img src={editDSIcon} alt="수정" />
                                                        </button>
                                                    </div>
                                                </li>
                                                <li className='col delete'>
                                                    <div className='innerDiv delete'>
                                                        <button onClick={e => onClickDeleteBtn(e, item.dDsId)}>
                                                            <img src={deleteDSIcon} alt="삭제" />
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className='btnsArea'>
                            <button className='createBtn' onClick={onClickCreateBtn}>추가<img src={createIconImg} alt={"추가"} /></button>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default ShowMonitDataStructures;