import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { API_URL } from '../../../config/constants';
import axios from 'axios';
import arrowIconImg from '../../../images/filledArrowIcon.png';
import CancelModal from './CancelModal';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';
import useAsync from '../../../hooks/useAsync';
import Loading from '../../components/Loading';
import DetailDataTRs from './components/DetailDataTRs';

function CreateData(props) {
    async function getSumDataStruct() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getSumDataStruct`, { sumDsId });
        return response.data;
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const sumDsId = searchParams.get("sumDsId");
    const { modelTypeArr } = useOutletContext();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        count: 0,
        decision: 'OK',
        modelId: 0,
        model: {
            modelType: '',
            modelName: '',
            modelNo: '',
        },
        ngFactor: '',
        comment: '',
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modelTypes, setModelTypes] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [modelNos, setModelNos] = useState([]);



    function onSubmit(e) {
        e.preventDefault();
        console.log(formData)
        if (formData.model.modelName.trim().length === 0 || formData.model.modelType.trim().length === 0 || formData.model.modelNo.trim().length === 0) {
            window.alert('차종/품명/품번이 입력되지 않았습니다.');
        } else {
            axios.post(`${API_URL}/monitoring-manage/createSumData`, {
                newValue: {
                    ...formData
                }
            }).then(res => {
                if (res.data.success) {
                    window.location.href = '/home/data/sumData';
                } else {

                };
            });
        };
    };

    async function onChange(e) {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function onOpenCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(true);
    };

    function onCloseCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(false);
    };

    function onCancel(e) {
        e.preventDefault();
        navigate('/home/data/sumData');
    };

    useEffect(() => {
        setModelTypes([...modelTypeArr.map(item => ({ label: item, value: item }))]);
    }, [modelTypeArr]);

    const decisionOpt = [
        { label: 'NG', value: 'NG' },
        { label: 'OK', value: 'OK' },
    ];

    const abnormalPatternOpt = [
        { label: 'OK', value: 'OK' },
        { label: 'NG', value: 'NG' },
    ];
    const ngFactorOpt = [
        { label: '없음', value: '' },
        { label: '원자재 - YS', value: '원자재 - YS' },
        { label: '원자재 - UTS', value: '원자재 - UTS' },
        { label: '하중 - Peak', value: '하중 - Peak' },
        { label: '하중 - 이상패턴', value: '하중 - 이상패턴' },
        { label: '치수 - 좌', value: '치수 - 좌' },
        { label: '치수 - 우', value: '치수 - 우' },
    ]

    const state = useAsync(getSumDataStruct);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return <div>실패</div>;

    return (
        <React.Fragment>
            <div className='dataCreateArea'>
                <p className='path'>데이터 관리<span className='below'>{'>'}</span>공정 요약<span className='below'>{'>'}</span>데이터추가</p>
                <h2 className='pageTitle'>데이터 추가</h2>
                <div className='pageContent'>
                    <form onSubmit={onSubmit} onChange={onChange}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor="count">카운트</label></td>
                                    <td className='inputTd' colSpan={5}><input id="count" name="count" type="number" defaultValue={formData.count} /></td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='decision'>판정</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, decision: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelType'>차종</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelTypes}
                                                defaultValue={{ label: '선택' }}
                                                onChange={async sel => {
                                                    await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                                        setModelNames([...res.data.map(item => ({ label: item, value: item }))]);
                                                        // modelNamesDropdown.current.value = "";
                                                        setModelNos([]);
                                                        // modelNosDropdown.current.value = "";
                                                        setFormData({
                                                            ...formData,
                                                            model: {
                                                                modelType: sel.value,
                                                                modelName: '',
                                                                modelNo: '',
                                                                modelId: 0,
                                                            }
                                                        });
                                                    }).catch(err => console.error(err));
                                                }}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelName'>품명</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelNames}
                                                value={formData.model.modelName === "" ? { label: '선택' } : modelNames.find(el => el.value === formData.model.modelName)}
                                                onChange={async sel => {
                                                    await axios.post(`${API_URL}/android/getModelNos`, { modelType: formData.model.modelType, modelName: sel.value }).then(res => {
                                                        setModelNos([...res.data.map(item => ({ label: item.modelNo, value: item }))]);
                                                        // modelNosDropdown.current.value = "";
                                                        setFormData({
                                                            ...formData,
                                                            model: {
                                                                ...formData.model,
                                                                modelName: sel.value,
                                                                modelNo: '',
                                                                modelId: 0,
                                                            }
                                                        });
                                                    }).catch(err => console.error(err));
                                                }}
                                                isSearchable={false}
                                                isDisabled={modelNames.length === 0}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='modelNo'>품번</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelNos}
                                                value={formData.model.modelNo === "" ? { label: '선택' } : modelNos.find(el => el.value.modelNo === formData.model.modelNo)}
                                                onChange={sel => {
                                                    setFormData({
                                                        ...formData,
                                                        modelId: sel.value.modelId,
                                                        model: {
                                                            ...formData.model,
                                                            modelNo: sel.value.modelNo,
                                                        }
                                                    })
                                                }}
                                                isSearchable={false}
                                                isDisabled={modelNos.length === 0}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <DetailDataTRs
                                    sumDs={result.data}
                                    data={formData}
                                    isNormalUser={false}
                                    onChange={onChange}
                                    onChangeBool={(name, sel) => setFormData({ ...formData, [name]: sel.value })}
                                    formData={formData}
                                />
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='ngFactor'>NG 요인</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={ngFactorOpt}
                                                defaultValue={{ label: '없음', value: '' }}
                                                onChange={sel => setFormData({ ...formData, ngFactor: sel.value })}
                                                isSearchable={false}
                                                maxMenuHeight={'140px'}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd' colSpan={2}><label htmlFor='comment'>코멘트</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='commentArea'>
                                            <textarea id='comment' name='comment' defaultValue={formData.comment} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='formBtnArea'>
                            <input className='submitBtn' type='submit' value="확인" />
                            <button className='cancelBtn' onClick={onOpenCancelModal}>취소</button>
                        </div>
                    </form>
                </div>
            </div>
            <CancelModal
                isOpen={modalIsOpen}
                onRequestClose={onCloseCancelModal}
                onSubmit={onCancel}
            />
        </React.Fragment>
    );
}

export default CreateData;