import React from 'react';

function DataTHs({ sumDs }) {
    if (!sumDs) return null;
    const { dataClass, indexType } = sumDs;
    return (
        <React.Fragment>
            {
                dataClass.map((dataCls, idx) => {
                    const dataTypeArr = indexType[idx];
                    const colAmount = dataTypeArr.reduce((prev, cur) => {
                        const curAmount = cur !== 'BOOLEAN' ? 2 : 1
                        return prev + curAmount;
                    }, 0)
                    return (
                        <th className='title' colSpan={colAmount + 1} key={`dataTHs_${idx}`}>
                            <div>{dataCls}</div>
                        </th>
                    )
                })
            }
        </React.Fragment>
    );
}

export default DataTHs;