import React from 'react';

function IndexLvTds({ outArr, title, inputType = 'text', onChange, isType = false }) {
    const defalutValue = inputType === 'number' ? 0 : ''
    const onlyEng = e => {
        e.target.value = e.target.value.replaceAll(/[0-9]|[^\!-z]/gi, "");
    };
    return (
        <React.Fragment>
            {
                outArr.map((innArr, index) => (innArr.map((_, idx) => (<td className='indexTd' key={`${title}_${index}_${idx}`}>
                    <input type={inputType} value={outArr[index][idx] ?? defalutValue} onChange={e => {
                        if (isType) onlyEng(e);
                        onChange(e, title, index, idx);
                    }} style={isType ? { textTransform: 'uppercase' } : {}} />
                </td>))))
            }
        </React.Fragment>
    );
}

export default IndexLvTds;