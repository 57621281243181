import React from 'react';

function IndexLvTdsWithInfo({ outArr, title, inputType = 'text', onChange }) {
    const defalutValue = inputType === 'number' ? 0 : ''
        return (
            <React.Fragment>
                {
                    outArr.map((innArr, index) => (innArr["data"].map((_, idx) => (<td className='indexTd' key={`${title}_${index}_${idx}`}>
                        <input type={inputType} value={outArr[index]["data"][idx] ?? defalutValue} onChange={e => {
                            onChange(e, title, index, idx);
                        }}/>
                    </td>))))
                }
            </React.Fragment>
        );
}

export default IndexLvTdsWithInfo;