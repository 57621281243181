import React from 'react';
import sortingBtnIconImg from '../../../../images/sortingBtnIcon.png';

function IndexNameTHs({ sumDs, onSorting, sortingAsc }) {
    if (!sumDs) return null;
    const { dataClass, indexNo, indexName, columnName, indexType } = sumDs;
    const columnNames = columnName.map(x => x.data);
    return (
        <React.Fragment>
            <tr>
                {
                    indexName.map((innerArr, outerIdx, outerArr) => {
                        return (
                            <React.Fragment key={`outerIdxName_${outerIdx}`}>
                                {
                                    innerArr.map((dataName, innerIdx) => {
                                        return (
                                            <th className={`title secondTh`} colSpan={indexType[outerIdx][innerIdx] !== 'BOOLEAN' ? 2 : 1} key={`idxName_${outerIdx}_${innerIdx}`}><div>{dataName}</div></th>
                                        );
                                    })
                                }
                                <th className={`title secondTh detail`} rowSpan={2}><div>상세</div></th>
                            </React.Fragment>
                        )
                    })
                }
            </tr>
            <tr>
                {
                    indexType.map((innerArr, outerIdx) => {
                        return innerArr.map((type, innerIdx) => {
                            if (type !== 'BOOLEAN') {
                                // indexType이 BOOLEAN이 아닌 경우
                                return (
                                    <React.Fragment key={`nameNSorting_${outerIdx}_${innerIdx}`}>
                                        <th className='thirdTh val'>
                                            <div className='nameNSorting'>
                                                <span>값</span>
                                                <button className='sortingBtn' onClick={e => onSorting(e, columnNames[outerIdx][innerIdx], !sortingAsc[columnNames[outerIdx][innerIdx]])}>
                                                    <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc[columnNames[outerIdx][innerIdx]] ? 'rotate(0)' : 'rotate(180deg)' }} />
                                                </button>
                                            </div>
                                        </th>
                                        <th className='thirdTh gap'>
                                            <div className='nameNSorting'>
                                                <span>편차</span>
                                                <button className='sortingBtn' onClick={e => onSorting(e, `${columnNames[outerIdx][innerIdx]}_gap`, !sortingAsc[`${columnNames[outerIdx][innerIdx]}_gap`])}>
                                                    <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc[`${columnNames[outerIdx][innerIdx]}_gap`] ? 'rotate(0)' : 'rotate(180deg)' }} />
                                                </button>
                                            </div>
                                        </th>
                                    </React.Fragment>
                                );
                            } else {
                                // BOOLEAN인 경우
                                return (
                                    <th className='thirdTh val' key={`nameNSorting_${outerIdx}_${innerIdx}`}>
                                        <div className='nameNSorting'>
                                            <span>값</span>
                                            <button className='sortingBtn' onClick={e => onSorting(e, columnNames[outerIdx][innerIdx], !sortingAsc[columnNames[outerIdx][innerIdx]])}>
                                                <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc[columnNames[outerIdx][innerIdx]] ? 'rotate(0)' : 'rotate(180deg)' }} />
                                            </button>
                                        </div>
                                    </th>
                                );
                            }
                        })
                    })
                }

            </tr>
        </React.Fragment>
    );
}

export default IndexNameTHs;