import React, { useEffect, useState } from 'react';
import CustomCheckbox from '../../../components/CustomCheckbox';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import DataTDs from './DataTDs';

function SumDataTbodyRow({
    index, item,
    chkedData, setChkedData,
    sumDs,
}) {
    const navigate = useNavigate();


    const dataDecisionOpt = [
        { label: "OK", value: "OK" },
        { label: "NG", value: "NG" },
    ];

    const onClickMaterialDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=material&dataId=${item.materialId}`);
    };
    const onClickLoadDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=load&dataId=${item.loadId}`);
    };
    const onClickSizeDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=size&dataId=${item.sizeId}`);
    };

    const time = new Date(item.time);
    const dataH = time.getHours();
    const dataM = time.getMinutes();
    const dataS = time.getSeconds();
    const fullYear = time.getFullYear();
    const month = parseInt(time.getMonth()) + 1 < 10 ? `0${parseInt(time.getMonth()) + 1}` : time.getMonth() + 1;
    const date = parseInt(time.getDate()) < 10 ? `0${parseInt(time.getDate())}` : time.getDate();
    const timeH = parseInt(dataH) < 10 ? `0${parseInt(dataH)}` : dataH;
    const timeM = parseInt(dataM) < 10 ? `0${parseInt(dataM)}` : dataM;
    const timeS = parseInt(dataS) < 10 ? `0${parseInt(dataS)}` : dataS;
    const timeStr = `${fullYear}-${month}-${date} ${timeH}:${timeM}:${timeS}`;
    return (
        <tr key={index}>
            <td className='dataChkbox'>
                <CustomCheckbox
                    id={`checkbox_${index}`}
                    checked={chkedData.includes(`${item.dataId}`)}
                    onChange={e => {
                        if (e.target.checked) {
                            setChkedData([...chkedData, e.target.value]);
                        } else {
                            setChkedData(chkedData.filter(el => el !== e.target.value));
                        };
                    }}
                    value={item.dataId}
                />
            </td>
            <td className='dataTime'>
                <p>{timeStr}</p>
            </td>
            <td className='dataDecision'>
                <Select
                    options={dataDecisionOpt}
                    value={dataDecisionOpt.find(el => el.value === item.decision)}
                    // onChange={sel => onChangeValue(index, 'decision', sel.value)}
                    isSearchable={false}
                    classNames={{
                        control: (state) => 'control',
                        container: (state) => 'selectBox',
                        dropdownIndicator: (state) => 'arrow',
                        menu: (state) => 'options',
                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                        singleValue: (state) => 'singleVal',
                        valueContainer: (state) => 'valueContainer',
                    }}
                    components={{
                        DropdownIndicator: (state) => null,
                        IndicatorSeparator: (state) => null,
                    }}
                    openMenuOnClick={false}
                />
            </td>
            <td className='dataModelType'>
                <p>{item.model.modelType}</p>
                <div className='hintArea'>
                    <span className='hintSpan'>{item.model.modelType}</span>
                </div>
            </td>
            <td className='dataModelName'>
                <p>{item.model.modelName}</p>
                <div className='hintArea'>
                    <span className='hintSpan'>{item.model.modelName}</span>
                </div>
            </td>
            <td className='dataModelNo'>
                <p>{item.model.modelNo}</p>
                <div className='hintArea'>
                    <span className='hintSpan'>{item.model.modelNo}</span>
                </div>
            </td>
            <td className='dataCount'>
                <p>{item.count}</p>
            </td>
            <DataTDs
                sumDs={sumDs}
                data={item}
            />
            {/* <td className='dataMaterialYS'>
                <p>{item.materialYS}</p>
            </td>
            <td className='dataMaterialYS_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.materialYS_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataMaterialUTS'>
                <p>{item.materialUTS}</p>
            </td>
            <td className='dataMaterialUTS_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.materialUTS_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataMaterialEU'>
                <p>{item.materialEU}</p>
            </td>
            <td className='dataMaterialEU_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.materialEU_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataMaterialDetail detailCol'>
                <button onClick={onClickMaterialDetail} disabled={item.ecttime === 'None' || !item.materialId}>상세</button>
            </td>
            <td className='dataPeak'>
                <p>{item.peak}</p>
            </td>
            <td className='dataPeak_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.peak_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataAbnormalPattern'>
                <p>{item.abnormalPattern}</p>
            </td>
            <td className='dataLoadDetail detailCol'>
                <button onClick={onClickLoadDetail} disabled={item.bolttime === 'None' || !item.loadId}>상세</button>
            </td>
            <td className='dataSizeLeft'>
                <p>{item.sizeLeft}</p>
            </td>
            <td className='dataSizeLeft_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.sizeLeft_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeLeft2'>
                <p>{item.sizeLeft2}</p>
            </td>
            <td className='dataSizeLeft2_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.sizeLeft2_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeRight'>
                <p>{item.sizeRight}</p>
            </td>
            <td className='dataSizeRight_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.sizeRight_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeRight2'>
                <p>{item.sizeRight2}</p>
            </td>
            <td className='dataSizeRight2_gap'>
                <div className='percentageDiv'>
                    <p>{Math.round(parseFloat(item.sizeRight2_gap ?? 0) * 1000) / 10}</p>
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeDetail detailCol'>
                <button onClick={onClickSizeDetail} disabled={item.disttime === 'None' || !item.sizeId}>상세</button>
            </td> */}
            <td className='dataNgFactor'>
                <p>{item.ngFactor ?? '없음'}</p>
            </td>
            <td className='dataComment'>
                <p>{item.comment}</p>
            </td>
            <td className='dataDetail'><button className='detailBtn' onClick={e => {
                e.preventDefault();
                navigate(`/home/data/sumData/detail?sumDsId=${sumDs.sumDsId}&dataId=${item.dataId}`)
            }}
            // disabled={userIsNormal}
            >상세</button></td>
        </tr>
    );
}

export default SumDataTbodyRow;