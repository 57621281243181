import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'rc-scrollbars';
import prevDataIcon from '../../../images/prevData.png';
import nextDataIcon from '../../../images/nextData.png';
import nextDataDisabledIcon from '../../../images/nextData_disabled.png'

function DataListMenu({ monitData, selSensorIdx, selDataIdx, setSelDataIdx, getPrevData, getMoreData, isLastData }) {
    console.log("좌측 데이터 랜더링 시작: ");
    const scrollbarRef = useRef();
    monitData?.sort(function compare(a, b) {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
    });
    useEffect(() => {
        scrollbarRef.current?.scrollToTop();
    }, [selSensorIdx]);
    console.log("좌측 데이터 랜더링 종료: ");
    return (
        <div className='dataTitleListArea'>
            {
                monitData?.length > 0 &&
                <React.Fragment>
                    <div className='selectDataTitle'>데이터 선택</div>
                    {/* <div className='dataTitleContainer' id='scrollArea'> */}
                    <button className='getPrevDataBtn' onClick={getPrevData}>
                        <img src={prevDataIcon} alt='이전' />
                    </button>
                    <Scrollbars className='dataTitleContainer' id='scrollArea' style={{ width: '255px', height: 'calc(100% - 38px - 62px)' }} ref={scrollbarRef}>
                        <ul className='dataTitleUl'>
                            {
                                monitData?.map((el, idx, arr) => {
                                    const isSelected = selDataIdx === idx;
                                    const date = el.date;
                                    return (
                                        <li className='dataTitleLi' key={idx}>
                                            <button
                                                className={isSelected ? 'dataTitleBtn selected' : 'dataTitleBtn'}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setSelDataIdx(idx);
                                                }}
                                            >
                                                {
                                                    `${date.substr(0, 4)}년 ${parseInt(date.substr(4, 2))}월 ${parseInt(date.substr(6, 2))}일 ${date.substr(9, 2)}:${date.substr(11, 2)}:${date.substr(13, 2)}`
                                                }
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Scrollbars>
                    {/* </div> */}
                    <button className='getNextDataBtn' onClick={getMoreData} disabled={isLastData} ><img src={isLastData ? nextDataDisabledIcon : nextDataIcon} alt='이후' /></button>
                </React.Fragment>
            }
        </div>
    );
}

export default React.memo(DataListMenu);