import React from 'react';
import { useNavigate } from 'react-router';

function DataTDs({ sumDs, data }) {
    const navigate = useNavigate();
    if (!sumDs) return null;
    const { dataClass, indexType, columnName } = sumDs;
    const dataColNames = columnName.map(el => el.data);
    const infoColNames = columnName.map(el => el.info);
    const onClickDetail = (e, name, dataId) => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=${name}&dataId=${dataId}`);
    };
    return (
        <React.Fragment>
            {
                indexType.map((idxTypeArr, index) => {
                    const dataCls = dataClass[index];
                    let clsEng;
                    switch (dataCls) {
                        case "원자재":
                            clsEng = 'material';
                            break;
                        case "하중":
                            clsEng = 'load';
                            break;
                        case "치수":
                            clsEng = 'size';
                            break;
                    }
                    return (
                        <React.Fragment key={`${data.dataId}_${index}`}>
                            {idxTypeArr.map((idxType, idx) => {
                                const colName = dataColNames[index][idx];
                                if (idxType !== 'BOOLEAN') {
                                    return (
                                        <React.Fragment key={`${data.dataId}_${colName}_${index}_${idx}`}>
                                            <td className={`${colName}`} >
                                                <p>{data[colName]}</p>
                                            </td>
                                            <td className={`${colName}_gap`}>
                                                <div className='percentageDiv'>
                                                    <p>{Math.round(parseFloat(data[`${colName}_gap`] ?? 0) * 1000) / 10}</p>
                                                    <span>%</span>
                                                </div>
                                            </td>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <td className={`${colName}`} key={`${data.dataId}_${colName}_${index}_${idx}`}>
                                            <p>{data[colName]}</p>
                                        </td>
                                    )
                                }
                            })}
                            <td className={`${clsEng}_detail detailCol`}>
                                <button onClick={e => onClickDetail(e, clsEng, data[infoColNames[index][0]])} disabled={!data[infoColNames[index][0]]}>상세</button>
                            </td>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    );
}

export default DataTDs;