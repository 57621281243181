import React from 'react';

function RawLvTds({ arr, title, inputType = 'text', onChange, isType = false }) {
    const defalutValue = inputType === 'number' ? 0 : ''
    console.log(arr)
    const onlyEng = e => {
        e.target.value = e.target.value.replaceAll(/[0-9]|[^\!-z]/gi, "");
    };
    return (
        <React.Fragment>
            {
                arr.map((_, index) => {
                    return (<td key={`${title}_${index}`}>
                        <input type={inputType} value={arr[index] ?? defalutValue} onChange={e => {
                            if (isType) onlyEng(e);
                            onChange(e, title, index);
                        }} min={1} style={isType ? { textTransform: 'uppercase' } : {}} />
                    </td>)
                })
            }
        </React.Fragment>
    );
}

export default RawLvTds;