import React from 'react';
import Select from 'react-select';
import arrowIconImg from '../../../../images/arrowIcon.png'

function DetailDataTRs({ sumDs, data, isNormalUser, onChange, onChangeBool, formData }) {
    console.log(sumDs)
    const { dataClass, columnName, indexName, indexNo, indexType } = sumDs;
    const columnNameData = columnName?.map(el => el.data);
    const boolOpt = [
        { label: 'OK', value: 'OK' },
        { label: 'NG', value: 'NG' },
    ];
    if (Object.keys(sumDs).length === 0) return null;
    return (
        <React.Fragment>
            {
                columnNameData.map((innerArr, outerIdx) => {
                    return innerArr.map((colName, innerIdx) => {
                        return (
                            <tr key={`${colName}_${innerIdx}_${outerIdx}`}>
                                {
                                    innerIdx === 0
                                        ? <td className='labelTd' rowSpan={indexNo[outerIdx]}>
                                            {dataClass[outerIdx]}
                                        </td>
                                        : null
                                }
                                <td className='labelTd'>
                                    {indexName[outerIdx][innerIdx]}
                                </td>
                                <td className='inputTd'>
                                    {
                                        indexType[outerIdx][innerIdx] !== 'BOOLEAN'
                                            ?
                                            isNormalUser
                                                ? `${!data[colName] ? '-' : data[colName]}`
                                                : <input id={colName} name={colName} type='number' value={formData[colName] ?? ''} onChange={onChange} readOnly={isNormalUser} />
                                            : isNormalUser
                                                ? `${boolOpt.find(el => el.value === formData[colName]).value}`
                                                :
                                                <div className='selectArea'>
                                                    <Select
                                                        options={boolOpt}
                                                        value={boolOpt.find(el => el.value === formData[colName])}
                                                        onChange={sel => onChangeBool(colName, sel)}
                                                        isSearchable={false}
                                                        classNames={{
                                                            control: (state) => 'control',
                                                            container: (state) => 'selectBox',
                                                            dropdownIndicator: (state) => 'arrow',
                                                            menu: (state) => 'options',
                                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                            singleValue: (state) => 'singleVal',
                                                            valueContainer: (state) => 'valueContainer',
                                                        }}
                                                        components={{
                                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                            IndicatorSeparator: (state) => null,
                                                        }}
                                                    />
                                                </div>


                                    }
                                </td>
                            </tr>
                        );
                    });
                })
            }
        </React.Fragment>
    );
}

export default DetailDataTRs;